import React from 'react';
import FooterButton from './ui/FooterUI';

const Footer = () => (
	<>
		<FooterButton href="https://docs.google.com/forms/d/e/1FAIpQLSdOxMWFWOgwleYBO9b-wKPjAxjCcCDTP0IRC4d7qqq4YHn5uw/viewform">
			Complete the form
		</FooterButton>
	</>
);

export default Footer;

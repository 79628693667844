import React from 'react';
import StyledButton from './ui/ButtonUI';

const FunctionButton = ({ onClick, text }) => (
	<>
		<StyledButton onClick={onClick}>{text}</StyledButton>
	</>
);

export default FunctionButton;

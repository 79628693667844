import { styled } from '@material-ui/core/styles';
import { Box, AppBar } from '@material-ui/core';

const BoxStyles = {
	display: 'flex',
	justifyContent: 'left',
	height: '80px',
	margin: '10px',
};

const BarStyles = {
	backgroundColor: '#090b25',
	flexGrow: 1,
	width: '100%',
};

export const HeaderBox = styled(Box)(BoxStyles);
export const HeaderBar = styled(AppBar)(BarStyles);

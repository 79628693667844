import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const GridStyles = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
};

const Grid = styled(Box)(GridStyles);

export default Grid;

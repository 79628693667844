import React from 'react';
import Information from './Information';
import ButtonGrid from './ButtonGrid';
import ContentBox from './ui/ContentUI';

const Content = () => (
	<>
		<ContentBox>
			<Information></Information>
			<ButtonGrid></ButtonGrid>
		</ContentBox>
	</>
);

export default Content;

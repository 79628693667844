import React from 'react';
import { HeaderBox, HeaderBar } from './ui/HeaderUI';

const Header = () => (
	<HeaderBar>
		<HeaderBox>
			<img
				width="245.8px"
				src="https://everlink.co/images/logo-main.png"
			></img>
		</HeaderBox>
	</HeaderBar>
);

export default Header;

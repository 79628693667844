import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const ContentStyles = {
	backgroundImage: 'url(/assets/pattern.png)',
	width: '100%',
	height: 'auto',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	paddingBottom: '30px',
};

const ContentBox = styled(Box)(ContentStyles);

export default ContentBox;

import React from 'react';
import { InformationBox, Title } from './ui/InformationUI';

const Information = () => (
	<>
		<InformationBox>
			<Title>Everlink Testing</Title>
		</InformationBox>
	</>
);

export default Information;

import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const BoxStyles = {
	marginTop: '100px',
	marginBottom: '10px',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	color: 'white',
};

const TitleStyles = {
	fontFamily: 'Konnect Medium',
	textAlign: 'center',
	boxSizing: 'border-box',
	width: '100%',
	marginLeft: 'auto',
	marginRight: 'auto',
	fontSize: '42px',
	fontWeight: '500',
	marginTop: '10px',
	marginBottom: '20px',
	letterSpacing: '0.05em',
	color: 'white',
};

const InstructionStyles = {
	display: 'flex',
	padding: '20px',
	margin: '10px 0',
	justifyContent: 'left',
	whiteSpace: 'initial',
	fontWeight: 'bold',
};

const PointStyles = {
	display: 'flex',
	paddingTop: '5px',
	paddingLeft: '20px',
	justifyContent: 'left',
	whiteSpace: 'initial',
};

export const InformationBox = styled(Box)(BoxStyles);
export const Title = styled(Box)(TitleStyles);
export const Instruction = styled(Box)(InstructionStyles);
export const Point = styled(Box)(PointStyles);

import React, { useEffect } from 'react';
import FunctionButton from './FunctionButton';
import Grid from './ui/ButtonGridUI';

const everlink = new window.Everlink('testappkey1256');

everlink.onError = (err) => {
	console.log(err);
};

everlink.onTokenFromAudio = (tokenObj) => {
	const { token } = tokenObj;
	const { error } = tokenObj;
	if (error) {
		console.log('error: ', error);
	} else {
		console.log('token from audio: ', token);
		alert(`Audio code detected \nToken returned: ${token}`);
	}
};

everlink.onNewTokenGenerated = (token) => {
	console.log('new token generated: ', token);
};

const ButtonGrid = () => {
	const updateTokenArray = async () => {
		let token1 = await everlink.getNewToken();
		token1 = token1.replace(/(\r\n|\n|\r)/gm, '');
		let token2 = await everlink.getNewToken();
		token2 = token2.replace(/(\r\n|\n|\r)/gm, '');
		window.localStorage.tokenArray = JSON.stringify([token1, token2]);
	};

	useEffect(() => {
		if (!window.localStorage.tokenArray) {
			updateTokenArray();
		}
	}, []);

	return (
		<>
			<Grid>
				<FunctionButton
					onClick={() =>
						everlink.saveSounds(
							JSON.parse(window.localStorage.tokenArray)
						)
					}
					text="Download sounds"
				></FunctionButton>
			</Grid>
			<Grid>
				<FunctionButton
					onClick={() => everlink.startListening({ isOffline: true })}
					text="Start Listening Offline"
				></FunctionButton>
				<FunctionButton
					onClick={() => {
						everlink.startListening({ isOffline: false });
					}}
					text="Start Listening Online"
				></FunctionButton>
			</Grid>
			<Grid>
				<FunctionButton
					onClick={() => {
						try {
							everlink.playFromToken(
								JSON.parse(window.localStorage.tokenArray)[0],
								{
									isOffline: true,
								}
							);
						} catch (err) {
							if (err.code === 'EJ8') {
								localStorage.removeItem('tokenArray');
								updateTokenArray();
							} else {
								console.log('err: ', err);
							}
						}
					}}
					text="Start Emitting Offline"
				></FunctionButton>
				<FunctionButton
					onClick={() => {
						try {
							everlink.playFromToken(
								JSON.parse(window.localStorage.tokenArray)[0],
								{ isOffline: false }
							);
						} catch (err) {
							if (err.code === 'EJ8') {
								localStorage.removeItem('tokenArray');
								updateTokenArray();
							}
						}
					}}
					text="Start Emitting Online"
				></FunctionButton>
			</Grid>
		</>
	);
};

export default ButtonGrid;

import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const ButtonStyles = {
	width: '40%',
	height: '80px',
	padding: '5px',
	margin: '15px',
	color: '#090b25',
	backgroundColor: 'white',
	cursor: 'pointer',
	outlineWidth: '10px',
	outlineColor: '#ff6b6b',
	borderRadius: '10px',
	'&:hover': {
		backgroundColor: '#090b25',
		color: '#ff6b6b',
	},
};

const StyledButton = styled(Button)(ButtonStyles);

export default StyledButton;

import React from 'react';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';

const App = () => (
	<>
		<Header></Header>
		<Content></Content>
		<Footer></Footer>
	</>
);

export default App;

import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const FooterStyles = {
	padding: '30px',
	width: '100%',
	height: 'auto',
	display: 'flex',
	justifyContent: 'center',
	color: 'white',
	'&:hover': {
		backgroundColor: '#090b25',
		color: '#ff6b6b',
	},
};

const FooterButton = styled(Button)(FooterStyles);

export default FooterButton;
